'use client';

import { useEffect, useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { useRouter, usePathname } from 'next/navigation';

import { signOut, useSession } from 'next-auth/react';

import { IoCopyOutline } from 'react-icons/io5';

import { useAuthStore } from '@/stores/userStore';

import { decrypt, validateIdsForVoucherModule } from '@/utils/core';

import { auth } from '@/utils/firebaseConfig';

import LoadingSpinner from '@/components/common/LoadingSpinner';

import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';

import avatar from '@/public/img/profile/avatar.png';

import { Skeleton } from '../ui/skeleton';
import { useToast } from '../ui/use-toast';

const UserMenu = () => {
  const { data: session } = useSession();
  const pathname = usePathname();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setActive] = useState(false);
  const [userId, setUserId] = useState<any>(null);
  const {
    account, clearStore,
    // setHydration
  } = useAuthStore();

  useEffect(() => {
    const fetchMessages = async () => {
      const userIdLocal = await decrypt(session?.user?.data ?? '', session?.user?.tag ?? '');
      setUserId(userIdLocal);
    };

    fetchMessages();
  }, [session?.user?.data, session?.user?.tag]);

  const { toast } = useToast();

  const toggleActive = () => {
    setActive(!isActive);
  };

  const handleHeaderBarClick = () => {
    toggleActive();
  };

  const handleLogout = async () => {
    try {
      await signOut();
      await auth.signOut();
      clearStore();
      localStorage.removeItem('email');
      localStorage.removeItem('phone');
      localStorage.removeItem('user_id');

      if (pathname.startsWith('/dashboard')) {
        router.push('/');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Logout failed', error);
    }
  };

  const showSpinner = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  if (!account) {
    return (
      <div className="relative">
        <Skeleton className="w-10 h-10 rounded-full bg-sivarbet-border" />
      </div>
    );
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(account?.topup_id || '').then(() => {
      toast({
        title: 'Codigo de deposito',
        description: 'El codigo de deposito ha sido copiado al portapapeles',
      });
    });
  };

  return (
    <div className="relative">
      {isLoading && <LoadingSpinner />}
      <DropdownMenu>
        <DropdownMenuTrigger asChild className="mr-2 pr-2 w-full">
          <div className="cursor-pointer">
            <Image
              src={avatar}
              onClick={handleHeaderBarClick}
              alt="logo"
              width={40}
              height={40}
              className="rounded-full w-10 md:w-8 lg:w-10 min-w-[32px] min-h-[32px]"
            />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="justify-center flex-col w-56 mt-3 bg-sivarbet-background border-sivarbet-border rounded text-sivarbet-text mr-7">
          <div className="flex flex-row items-center justify-center gap-3 p-2 mt-1 rounded-full bg-sivarbet-secondary-bg text-center cursor-pointer" onClick={handleCopy}>
            <span className="bg-[radial-gradient(circle,_rgb(149,251,63)_60%,_rgb(70,252,109)_100%)] bg-clip-text text-transparent">
              {account.topup_id}
            </span>
            <IoCopyOutline className="text-[radial-gradient(circle,_rgb(149,251,63)_60%,_rgb(70,252,109)_100%)] cursor-pointer" />
          </div>
          <Link href="/dashboard" prefetch className="dropdown-item">
            <DropdownMenuItem
              onClick={showSpinner}
              className="dropdown-item focus:text-sivarbet-text"
            >
              <span className="icon icon-user text-xl mr-2" />
              <span>Perfil</span>
            </DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator className="bg-sivarbet-border" />
          <Link
            href="/dashboard/transactions"
            prefetch
            className="dropdown-item"
          >
            <DropdownMenuItem
              onClick={showSpinner}
              className="dropdown-item focus:text-sivarbet-text"
            >
              <span className="icon icon-transaction text-xl mr-2" />
              <span>Transacciones</span>
            </DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator className="bg-sivarbet-border" />
          <Link
            href="/dashboard/withdrawals"
            prefetch
            className="dropdown-item"
          >
            <DropdownMenuItem
              onClick={showSpinner}
              className="dropdown-item focus:text-sivarbet-text"
            >
              <span className="icon icon-withdraw text-xl mr-2" />
              <span>Retiros</span>
            </DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator className="bg-sivarbet-border" />
          {validateIdsForVoucherModule(userId) && (
            <Link
              href="/dashboard/vouchers"
              prefetch
              className="dropdown-item"
            >
              <DropdownMenuItem
                onClick={showSpinner}
                className="dropdown-item focus:text-sivarbet-text"
              >
                <span className="icon icon-withdraw text-xl mr-2" />
                <span>Comprobantes bancarios</span>
              </DropdownMenuItem>
            </Link>
          )}
          <DropdownMenuSeparator className="bg-sivarbet-border" />
          <DropdownMenuItem onClick={handleLogout} className="dropdown-item">
            <span className="icon icon-out text-xl mr-2 text-sivarbet-primary" />
            <span className="fw-bold text-sivarbet-primary">Cerrar Sesión</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default UserMenu;
