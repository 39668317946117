const VoucherInProcess = () => {
  return (
    <div className="w-full h-full py-3 font-medium border-2 border-sivarbet-border text-center">
      <p className="text-sm">
        Querido usuario, en el momento tiene un comprobante en trámite.
      </p>
      <p className="text-sm">
        En un momento se procesará y podrá enviar otro.
      </p>
    </div>
  );
};

export default VoucherInProcess;
