'use client';

import dynamic from 'next/dynamic';
import Link from 'next/link';

import { Dialog, DialogTrigger } from '@/components/ui/dialog';

import SocialLinks from './SocialLinks';

const DynamicLazyModalContent = dynamic(() => { return import('./LazyModalContent'); }, {
  loading: () => { return <p>Loading...</p>; },
});

const FooterLinks: React.FC = () => {
  return (
    <>
      <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
        <div className="mb-8 lg:mb-0">
          <h4 className="info-header">Información General</h4>
          <ul className="list-secondary-gap-2">
            <li>
              <Dialog modal>
                <DialogTrigger asChild className="cursor-pointer">
                  <span>Reglas de apuestas</span>
                </DialogTrigger>
                <DynamicLazyModalContent
                  title="Reglas de apuestas"
                  getDataFunction="getBettingrules"
                  app="hondubet"
                />
              </Dialog>
            </li>
            <li>
              <Dialog modal>
                <DialogTrigger asChild className="cursor-pointer">
                  <span>Depósitos</span>
                </DialogTrigger>
                <DynamicLazyModalContent
                  title="Depositos"
                  getDataFunction="getDepositsWithdrawals"
                  app="hondubet"
                />
              </Dialog>
            </li>
            <li>
              <Dialog modal>
                <DialogTrigger asChild className="cursor-pointer">
                  <span>Retiros</span>
                </DialogTrigger>
                <DynamicLazyModalContent
                  title="Retiros"
                  getDataFunction="getDepositsWithdrawals"
                  app="hondubet"
                />
              </Dialog>
            </li>
            <li>
              <Dialog modal>
                <DialogTrigger asChild className="cursor-pointer">
                  <span>Límite de Apuestas</span>
                </DialogTrigger>
                <DynamicLazyModalContent
                  title="Limite de Apuestas"
                  getDataFunction="getBettinglimit"
                  app="hondubet"
                />
              </Dialog>
            </li>
          </ul>
        </div>
        <div className="mb-8 lg:mb-0">
          <h4 className="info-header">Nuestras Secciones</h4>
          <ul className="list-secondary-gap-2">
            <li>
              <Link href="/" prefetch={false}>Inicio</Link>
            </li>
            <li>
              <Link href="/casino" prefetch={false}>Casino</Link>
            </li>
            <li>
              <Link href="/deportes" prefetch={false}>Deportes</Link>
            </li>
            <li>
              <Link href="/dashboard" prefetch={false}>Perfil</Link>
            </li>
            <li>
              <Link href="/dashboard/withdrawals" prefetch={false}>Retiros</Link>
            </li>
          </ul>
        </div>
        <div className="mb-8 lg:mb-0">
          <h4 className="info-header">Seguridad & Privacidad</h4>
          <ul className="list-secondary-gap-2">
            <li>
              <Dialog modal>
                <DialogTrigger asChild className="cursor-pointer">
                  <span>Política de Privacidad </span>
                </DialogTrigger>
                <DynamicLazyModalContent
                  title="Politica de Privacidad"
                  getDataFunction="getSanityPrivacyPolicy"
                  app="hondubet"
                />
              </Dialog>
            </li>
            <li>
              <Dialog modal>
                <DialogTrigger asChild className="cursor-pointer">
                  <span>Términos y Condiciones</span>
                </DialogTrigger>
                <DynamicLazyModalContent
                  title="Términos y Condiciones"
                  getDataFunction="getSanityPosts"
                  app="hondubet"
                />
              </Dialog>
            </li>
            <li>
              <Dialog modal>
                <DialogTrigger asChild className="cursor-pointer">
                  <span>Condiciones de Bonos</span>
                </DialogTrigger>
                <DynamicLazyModalContent
                  title="Condiciones de Bonos"
                  getDataFunction="getCategory"
                  app="hondubet"
                />
              </Dialog>
            </li>
            <li>
              <Dialog modal>
                <DialogTrigger asChild className="cursor-pointer">
                  <span>Juego Responsable</span>
                </DialogTrigger>
                <DynamicLazyModalContent
                  title="Juego Responsable"
                  getDataFunction="getResponsibility"
                  app="hondubet"
                />
              </Dialog>
            </li>
            <li>
              <Dialog modal>
                <DialogTrigger asChild className="cursor-pointer">
                  <span>Políticas KYC</span>
                </DialogTrigger>
                <DynamicLazyModalContent
                  title="Políticas KYC"
                  getDataFunction="getKycpolicies"
                  app="hondubet"
                />
              </Dialog>
            </li>
            <li>
              <Dialog modal>
                <DialogTrigger asChild className="cursor-pointer">
                  <span>Anti-Lavado de Dinero</span>
                </DialogTrigger>
                <DynamicLazyModalContent
                  title="Lavado de Dinero"
                  getDataFunction="getMoneyLaundering"
                  app="hondubet"
                />
              </Dialog>
            </li>
          </ul>
        </div>
        <div className="mb-8 lg:mb-0">
          <h4 className="info-header">¿Necesitas ayuda?</h4>
          <ul className="list-secondary-gap-2">
            <li>
              <Dialog modal>
                <DialogTrigger asChild className="cursor-pointer">
                  <span>Preguntas Frecuentes</span>
                </DialogTrigger>
                <DynamicLazyModalContent
                  title="Preguntas Frecuentes"
                  getDataFunction="getFrequentQuestions"
                  app="hondubet"
                />
              </Dialog>
            </li>
            <li>
              <Link href="https://api.whatsapp.com/send?phone=50492062735&text=%C2%A1Hola">
                Contáctanos
              </Link>
            </li>
            <li>
              <Dialog modal>
                <DialogTrigger asChild className="cursor-pointer">
                  <span>Resolución de Disputas</span>
                </DialogTrigger>
                <DynamicLazyModalContent
                  title="Resolución de Disputas"
                  getDataFunction="getDisputeResolutionPost"
                  app="hondubet"
                />
              </Dialog>
            </li>
            <li>
              <Link href="/nuevo-agente" prefetch={false}>¿Quieres ser Agente Hondubet?</Link>
            </li>
          </ul>
        </div>
      </div>
      <SocialLinks />
    </>

  );
};

export default FooterLinks;
