export const messages = {
  register: 'Regístrate',
  signUpTo: 'Registrate a',
  email: 'Correo electrónico',
  password: 'Contraseña',
  idAffiliatemessage: 'ID Afiliado',
  alreadyHaveAnAccount: '¿Ya tienes una cuenta?  ',
  dontHaveAnAccount: '¿No tienes una cuenta?  ',
  enterHere: 'Entrar aquí',
  enterYourMobilePhone: 'Introduzca su telefono movil',
  login: 'Entrar',
  orContinueWith: 'o continuar con',
  orRegisterWith: 'o regístrate con',
  enterTo: 'Entrar a',
  registerTo: 'Regístrate a',
  affiliateCode: 'Codigo Afiliado',
  registerWith: 'Registrate con',
  google: 'Google',
  yourPhone: 'Tu teléfono',
  yourEmail: 'Tu email',
  continueWith: 'Continuar con',
  enterPhoneCode: 'Introduzca el código enviado al teléfono',
  validatePhoneNumber: 'Validar teléfono',
  emailSent: 'Email de verificación enviado',
  forgotPassword: '¿Olvidaste tu contraseña?',

  messagesRegister: 'Confirmo que tengo 21 años y que he leído los Términos del Servicio',

  errorConfirmCode: 'Error al confirmar el código',
  errorExpiredCaptcha: 'El captcha ha expirado. Por favor, inténtalo de nuevo.',
  errorLoginAuth: 'Error al iniciar sesión, inténtelo nuevamente',
  errorPopupClosedByUser: 'Se ha cerrado la ventana de inicio de sesión, inténtelo nuevamente',
  errorSendPhoneCode: 'Error al enviar el código al celular',
  errorTryAgain: 'Error, inténtelo nuevamente',
  errorUserRegistered: 'Ya se encuentra registrado, por favor, inicie sesión',
  errorVerifyPhoneCode: 'Error al verificar el código',
  errorInvalidEmail: 'El correo electrónico es inválido',
  errorInvalidPassword: 'La contraseña es inválida',
  errorInvalidPhoneNumber: 'El numero de teléfono y/o contraseña son inválidos',
  errorInInvalidPhoneNumber: 'Numero de teléfono inválido',
  errorIsRequired: 'El campo es requerido',
  errorAgeVerification: 'Debes ser mayor de 21 años para poder registrarte',
  errorInvalidCredentials: 'El correo electrónico y/o contraseña son inválidas',
  errorCheckbox: 'Debes confirmar que eres mayor de 21 años para poder registrarte',
  errorPasswordsDoNotMatch: 'Las contraseñas no coinciden',

  errorAccount: 'El número de cuenta es necesario y debe tener maximo 14 caracteres',
  errorAmount: 'Error al seleccionar el monto',
  errorWithdrawalRemarks: 'DNI es requerido y debe tener mínimo 11 caracteres y máximo 14 caracteres',
  errorWithdrawalRemarksFormat: 'DNI no valido',
  errorBank: 'El banco es necesario',
  errorTypeAccount: 'El tipo de cuenta es necesario',

  errorFirstName: 'El nombre es necesario',
  errorSecondName: 'El segundo nombre es necesario',
  errorLastName: 'El apellido es necesario',
  errorNickname: 'El apodo es necesario',
  errorPhone: 'El teléfono es necesario',
  errorBirthdate: 'La fecha de nacimiento es necesaria',
  errorAddress: 'La dirección es necesaria',
  errorCity: 'La ciudad es necesaria',
  errorCountry: 'El país es necesario',
  errorIncorrectFormatValue: 'El valor ingresado es incorrecto',
  errorIncorrectVoucher: 'El número de referencia es incorrecto',
  errorIncorrectVoucherValue: 'El valor mínimo debe ser de L750 y el máximo de L20.000',
  errorIncorrectVoucherFile: 'El archivo del comprobante es necesario',

  // ,
  // aboutUs: 'Acerca de nosotros',
  // allGames: 'Todos los juegos',
  // allRightsReserved: 'Todos los derechos reservados',
  // basketball: 'Baloncesto',
  // bettingLimit: 'Limite de apuesta',
  // bettingRules: 'Reglas de apuesta',
  // bonusConditions: 'Condiciones de bono',
  // casino: 'Casino',
  // contactUs: 'Contáctanos',
  // deposits: 'Depositos',
  // disputeResolution: 'Resolución de disputas',
  // doYouNeedHelp: '¿Necesitas ayuda?',
  //
  // esports: 'Esports',
  // faqs: 'Preguntas frecuentes',
  // favorites: 'Favoritos',
  // followUs: 'Síguenos',
  // forgotYourPassword: '¿Se te olvidó la contraseña?',
  // hockey: 'Hockey',
  // home: 'Inicio',
  // kycPolicies: 'Políticas de KYC',
  // liveEvents: 'Eventos en vivo',
  // moneyLaundering: 'Lavado de dinero',
  // ourSections: 'Nuestras secciones',
  // overview: 'Información General',
  // popularEvents: 'Eventos populares',
  // popularLeagues: 'Ligas populares',
  // profile: 'Perfil',
  // promotions: 'Promociones',
  // recommended: 'Recomendados',
  // registerWith: 'Regístrate con',
  // responsibleGaming: 'Juego responsable',
  // securityAndPrivacy: 'Seguridad & privacidad',
  // signUp: 'Registrarse',
  // soccer: 'Futbol',
  // sports: 'Deportes',
  // suggestedForYou: 'Sugeridos para ti',
  // support: 'Soporte',
  // tennis: 'Tenis',
  // termsAndConditions: 'Términos & condiciones',
  // tournaments: 'Torneos',
  // volleyball: 'Voley',
  // withdrawals: 'Retiros',
  // workWithUs: 'Trabaja con Nosotros',
};
