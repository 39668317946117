import { UserKYCLevel } from '@/enums/core';

export const referralsRules = {
  kyc: UserKYCLevel.APPROVED,
  minDeposit: 300,
  fullProfile: true,
  redeemDays: 15,
  commissions: [
    {
      minReferral: 0, maxReferral: 999999999999999, comission: 250.00,
    },
  ],
};
