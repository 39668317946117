'use client';

import type React from 'react';
import { useState } from 'react';

import { motion } from 'framer-motion';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa6';

const Description: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="max-w-5xl flex flex-col md:items-start text-wrap p-4 space-y-4 text-slate-400 mt-3">
      <h1 className="text-base md:text-base md:text-lg font-bold mt-2 text-center">
        Apuestas Deportivas en línea en Honduras en Hondubet.com
      </h1>

      <motion.div
        initial={{ height: '50px' }}
        animate={{ height: isExpanded ? 'auto' : '50px' }}
        transition={{ duration: 0.5 }}
        className={`overflow-hidden relative text-start ${
          !isExpanded ? 'after:absolute after:bottom-0 after:left-0 after:right-0 after:h-full after:bg-gradient-to-t after:from-sivarbet-background after:to-transparent after:pointer-events-none dark:after:from-background' : ''
        }`}
      >
        <p className="text-xs">
          Hondubet.com ofrece una amplia oferta en apuestas deportivas. Regístrate y comienza a ganar con tu pasión por
          los deportes. Somos la mejor casa de apuestas de Honduras, contamos con la plataforma más completa,
          tecnológica y segura de todo el país, además de ofrecer gran cantidad de mercados de apuestas en todos los
          deportes y ligas del mundo.
        </p>

        <p className="text-xs">
          En Hondubet.com puedes hacer tus apuestas deportivas con más 100 eventos deportivos al día. Es tu momento de
          pronosticar, retarte a ti mismo y a tus amigos a adivinar los marcadores exactos, ganadores y muchos más
          mercados dentro de un mismo evento deportivo. Te enfrentarás a escenarios tales como, ¿Qué equipo ganará?
          ¿Quién marcará más goles? ¿Quién clasificará a la siguiente ronda? O ¿Cuántos puntos se anotarán? Además de
          contar con las cuotas más altas y competitivas de todo Honduras.
        </p>

        <ul className="text-xs list-disc pl-5">
          <li>Apuestas deportivas en fútbol en vivo</li>
          <li>Apuestas en liga de Honduras (Liga Hondubet)</li>
          <li>Apuestas en Liga de México (Liga MX)</li>
          <li>Apuestas en Champions League</li>
          <li>Apuestas en NBA</li>
          <li>Apuestas en tenis</li>
          <li>Apuestas en NFL</li>
          <li>Apuestas en Boxeo</li>
        </ul>

        <p className="text-xs">
          Y muchos más deportes, con diferentes mercados en los que podrás jugar, apostar y pronosticar desde tu casa o
          desde cualquier lugar en el que te encuentres.
        </p>

        <h2 className="text-base md:text-lg font-semibold mt-8 mb-4">
          Apuestas Deportivas en vivo y Casino en línea en Hondubet.com
        </h2>

        <p className="text-xs">
          Hondubet.com ofrece la mejor experiencia, un amplio repertorio de apuestas en casino en línea. Juega y gana
          dinero real en el casino virtual de Hondubet.com de forma segura y divertida.
        </p>

        <p className="text-xs">
          Tenemos una amplia gama de juegos de casino, son más de 200 opciones en las que se incluyen tragamonedas,
          ruleta, blackjack, bingo, póker, casino en vivo y rueda de la fortuna. Empieza hoy a disfrutar de los mejores
          juegos del mercado de Honduras, con gráficos y premios increíbles.
        </p>

        <h2 className="text-base md:text-lg font-semibold mt-8 mb-4">
          ¿Cómo apostar en Hondubet.com? Paso a paso de como ganar dinero con Apuestas Deportivas en Honduras
        </h2>

        <p className="text-xs">
          Es momento de disfrutar y ganar dinero con Hondubet.com, fácil sigue está guía rápida:
        </p>

        <ol className="list-decimal pl-5">
          <li>
            Debes ser mayor de 21 años y registrarte en Hondubet.com, asegúrate de poner tus datos personales precisos y
            verificar tu cuenta. Recibirás un bono por tu registro sin depósito.
          </li>
          <li>
            Realiza tu primer depósito para empezar a apostar, además recibirás un bono por tu primera recarga,
            aprovéchalo.
          </li>
          <li>Elige tu deporte, liga y evento favorito para apostar y ganar dinero.</li>
        </ol>

        <h2 className="text-base md:text-lg font-semibold mt-8 mb-4">
          Apuestas deportivas de fútbol en Hondubet.com: Cuotas, mercados y métodos de apuesta explicados.
        </h2>

        <p className="text-xs">
          El fútbol es el deporte con mayor popularidad en el mundo y también el más apostado en las apuestas
          deportivas. Acá te explicamos los mercados más comunes:
        </p>

        <ul className="text-xs list-disc pl-5">
          <li>
            <strong>1x2 (1 es local, X es empate, 2 es visitante):</strong>
            {' '}
            1x2 es el mercado por excelencia de las
            apuestas deportivas en fútbol, si te decides por este mercado tendrás que elegir uno de los 3.
          </li>
          <li>
            <strong>Ambos equipos marcan:</strong>
            {' '}
            Es aquella apuesta donde pronósticas que ambos equipos anotarán gol.
            Por ejemplo, si juega Honduras contra México y el partido finaliza 3-1 a favor de La H, acertarás tu
            pronóstico.
          </li>
          <li>
            <strong>Total de goles:</strong>
            {' '}
            Consiste en pronosticar cuál es la cantidad de goles que habrá en un
            determinado partido.
          </li>
          <li>
            <strong>Primer gol:</strong>
            {' '}
            Apuesta a que Honduras marcará el primer gol frente a México. Si apuestas L200
            y la cuota es de 2.0 ganarás L400.
          </li>
        </ul>

        <h2 className="text-base md:text-lg font-semibold mt-8 mb-4">Tipos de apuestas deportivas en Hondubet.com</h2>

        <h3 className="text-base md:text-lg font-semibold mt-4 mb-2">Apuestas simples</h3>
        <p className="text-xs">
          La apuesta simple o sencilla consiste en escoger un un evento deportivo y seleccionar un único mercado y
          resultado para dicho evento. Elige tu mercado, ingresa el valor que desees apostar y confirma tu apuesta.
          Recuerda siempre que las ganancias se calculan multiplicando la cantidad jugada por la cuota.
        </p>

        <h3 className="text-base md:text-lg font-semibold mt-4 mb-2">Apuestas combinadas o Parlay (Parley)</h3>
        <p className="text-xs">
          En esta modalidad de apuesta podrás combinar varios resultados en diferentes eventos. Podrás juntar hasta 15
          partidos de diferentes deportes y las cuotas se multiplicarán entre si, lo que aumentará exponencialmente tus
          ganancias.
        </p>

        <h2 className="text-base md:text-lg font-semibold mt-8 mb-4">
          Bono de registro en Hondubet.com: Recibe L100 o 100 jugadas gratis de casino y sin depósito.
        </h2>
        <p className="text-xs">
          En Hondubet.com tu eliges tu regalo, de esta manera te damos la mejor de las bienvenidas. Lo único que debes
          hacer es registrarte, verificar tu cuenta y disfrutar de tus primeras apuestas con este bono exclusivo de
          bienvenida. Consulta aquí los términos y condiciones con cada detalle.
        </p>

        <h2 className="text-base md:text-lg font-semibold mt-8 mb-4">
          Bono de primer depósito en Hondubet.com: Recibe el 100% de tu primer depósito.
        </h2>
        <p className="text-xs">
          Duplica tu primer depósito en Hondubet.com. Por tu primera recarga desde L250 ganarás el doble de este. Para
          empezar a ganar en la mejor plataforma de apuestas deportivas y casino de Honduras.
        </p>
      </motion.div>

      <div
        className="text-sivarbet-primary hover:text-sivarbet-primary-hover font-bold self-center cursor-pointer"
        onClick={() => {
          return setIsExpanded(!isExpanded);
        }}
      >
        {isExpanded ? (
          <div className="flex justify-center">
            <span className="text-sm">Ver menos</span>
            <FaCaretUp />
          </div>
        ) : (
          <div className="flex justify-center">
            <span className="text-sm">Ver más</span>
            <FaCaretDown />
          </div>
        )}
      </div>
    </div>
  );
};

export default Description;
