export enum RegisterStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
}

export enum AppStyles {
  TOAST_PRIMARY = 'bg-sivarbet-primary border-0 text-white',
  TOAST_SECONDARY = 'bg-sivarbet-secondary border-0 text-white',
  TOAST_DANGER = 'bg-red-600 border-0 text-white',
}

export enum Vendors {
  GOOGLE = 1,
  EMAIL = 2,
  PHONE = 3,
}

export enum TransactionTypes {
  RECHARGE = 1,
  DEPOSIT = 2,
  WITHDRAWL = 3,
  ADJUST_DEP = 4,
  ADJUST_DISP = 5,
  RECHARGE_DISP = 6,
  COMMISSION = 7,
}

export enum Currency {
  HNL = 1,
  USD = 2,
}

export enum Gateway {
  MANUAL = 1,
  BANK = 2,
  CASH = 3,
  CHIVO = 4,
  COINPAYMENTS = 5,
  PAYPHONE = 6,
  NICO = 7,
  TIGO = 8,
  SOMPOPO = 9,
  GINIH = 10,
  UNLIMIT = 11,
}

export enum CalendarRange {
  TODAY = 0,
  YESTERDAY = 1,
  THREE_DAYS_AGO = 3,
  ONE_WEEK_AGO = 7,
  LAST_30_DAYS = 30,
}

export enum TransactionStates {
  COMPLETE = 1,
  APPROVED = 2,
  ISSUED = 3,
  REJECTED = 4,
  CANCELLED = 5,
  ROLLBACK = 6,
  PENDING = 7,
  FINISHED = 8,
}

export enum AuthType {
  SIGNIN = 'signin',
  REGISTER = 'register',
}

export enum WalletType {
  BANK = 1,
  WALLET = 2,
}

export enum WalletMethod {
  WITHDRAW = 1,
  DEPOSIT = 2,
}

export enum WalletCurrency {
  BTC = 1,
  USDT = 2,
  ETH = 3,
  SOL = 4,
}

export enum WalletNetwork {
  DEFAULT = 0,
  AHORROS = 1,
  CORRIENTE = 2,
  CHIVO = 3,
  TRON = 4,
  LTCT = 5,
  BTC = 6,
}

export enum UserKYCStatus {
  DEFAULT = 0,
  PENDING = 1,
  APPROVED = 2,
  REJECTED = 3,
}

export enum UserKYCLevel {
  DEFAULT = 1,
  APPROVED = 2,
}

export enum ProfileGender {
  DEFAULT = 0,
  MALE = 1,
  FEMALE = 2,
}

export enum ProfileDocument {
  DNI = 1,
  PASSPORT = 2,
}

export enum BetStatus {
  DEFAULT = '',
  LOST = 'Lost',
  WON = 'Won',
  CASHOUT = 'Cashout',
  VOID = 'Cancelada-Void',
  REJECTED = 'Cancelada-Rejected',
  PENDING = 'Pending',
  CASHOUT_PARCIAL = 'Cashout Parcial',
  OPEN = 'Open',
}

export enum GameResult {
  DEFAULT = 0,
  BET = 1,
  WIN = 2,
  ROLLBACK = 3,
}

export enum GameVendor {
  SOFTSWISS = 1,
}

export enum MovementSource {
  TRANSACTION = 1,
  GAME = 2,
  BET = 3,
}

export enum WithdrawalMethod {
  CASH = 1,
  BANK = 2,
  CRYPTO = 3,
}

export enum TransactionType {
  RECHARGE = 'recarga',
  WITHDRAWAL = 'retiro',
  BET = 'apuesta',
}

export enum ErrorCodes {
  NO_AUTHORIZATION = 1000,
  NO_JWT_TOKEN = 10001,
}

export enum BonusType {
  SPORTS = 'sports',
  CASINO = 'casino',
  PERCENTAGE = 'percentage',
}

export enum BonusStatusSport {
  PENDING = 'Waiting',
  APPLIED = 'Active',
  CLOSED = 'Closed',
  AWARDED = 'Awarded',
  EXPIRED = 'Expired',
}

export enum BonusStatusCasino {
  PENDING = 1,
  APPLIED = 2,
  CLOSED = 3,
  EXPIRED = 4,
  ROLLED_BACK = 5,
}

export enum BonusDispatcher {
  REGISTER = 1,
  FIRST_DEPOSIT = 20,
  SECOND_DEPOSIT = 21,
  THIRD_DEPOSIT = 22,
  NEXT_DEPOSIT = 23,
}

export enum BonusTypeAltenar {
  CODE = 'CreateByCode',
  DEPOSIT = 'CreateByDeposit',
}
export enum VoucherBank {
  BANKPAIS = 1,
  BAC = 2,
}

export enum VoucherStatus {
  DEFAULT = 0,
  APPROVED = 1,
  DENIED = 2,
}

export enum BonusCasinoGameType {
  FREESPINS = 'freespin',
}

export enum GatewayType {
  CASH = 'efectivo',
  BANK = 'banco',
}

export enum VoucherTypes {
  REQUESTED = 0,
  APPROVED = 1,
  REJECTED = 2,
  ANNULLED = 3,
  ALL = 100,
}

export enum ReferralStatus {
  PENDING = 1,
  APPROVAL = 2,
  REJECTED = 3,
  COMPLETED = 4,
  EXPIRED = 5,
}
