'use client';

import { useEffect, useState } from 'react';

import Image from 'next/image';

import { motion } from 'framer-motion';

import { Promotion } from '@/features/Promotions/components/PromotionsContent';

import { Card, CardContent } from '@/components/ui/card';

interface TimeLeft {
  days: string
  hours: string
  minutes: string
}

interface TournamentItemProps {
  title: string
  prizePool: number | string
  image: string
  cmsTournament: Promotion
}

const formatUnits = (unit: string) => {
  switch (unit) {
    case 'days':
      return 'Días';
    case 'hours':
      return 'Horas';
    case 'minutes':
      return 'Minutos';
    default:
      return '';
  }
};

export default function TournamentItem({
  title,
  prizePool,
  image,
  cmsTournament,
}: TournamentItemProps) {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: '00',
    hours: '00',
    minutes: '00',
  });
  const [status, setStatus] = useState('');

  useEffect(() => {
    const calculateTimeLeft = () => {
      const offset = 6 * 60 * 60 * 1000;
      const startTime = new Date(cmsTournament.startDate).getTime();
      const endTime = new Date(cmsTournament.endDate).getTime();
      const now = new Date().getTime() - offset;

      const updateCountdown = (difference: number) => {
        if (difference > 0) {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

          setTimeLeft({
            days: days.toString().padStart(2, '0'),
            hours: hours.toString().padStart(2, '0'),
            minutes: minutes.toString().padStart(2, '0'),
          });
        }
      };

      if (now < startTime) {
        setStatus('Próximamente');
        updateCountdown(startTime - now);
      } else if (now >= startTime && now <= endTime) {
        setStatus('En curso');
        updateCountdown(endTime - now);
      } else {
        setStatus('Completado');
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    return () => { return clearInterval(timer); };
  }, [cmsTournament]);

  return (
    <Card className="relative w-full h-[200px] md:h-[250px] overflow-hidden rounded-2xl border-0 bg-transparent">
      <Image
        src={image}
        alt={title}
        fill
        className="object-cover transition-transform duration-300 hover:scale-105"
        priority
      />
      <CardContent className="relative h-full z-10 p-4 flex flex-col justify-around bg-gradient-to-t from-black/80 to-transparent">
        <div className="space-y-1">
          <div className="flex justify-between items-center">
            <p className="text-xs font-medium text-white/80 uppercase tracking-wider">
              {status}
            </p>
          </div>
          <div className="flex bg-slate-400/30 backdrop-blur-sm rounded-lg w-fit">
            {Object.entries(timeLeft).map(([unit, value]) => {
              return (
                <div
                  key={unit}
                  className=" p-2 text-center min-w-[70px]"
                >
                  <div className="text-xl font-bold text-white">
                    {value}
                  </div>
                  <div className="text-[10px] text-white/60 uppercase font-medium">
                    {formatUnits(unit)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="space-y-2">
          <h3 className="text-xl font-bold text-white leading-tight">
            {title}
          </h3>
          <div className="space-y-1">
            <p className="text-xs text-white/60 uppercase">Bolsa de premios</p>
            <div className="text-3xl font-extrabold">
              <motion.p
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  duration: 0.8,
                  ease: 'easeOut',
                }}
                className="text-3xl font-extrabold bg-gradient-to-l from-pink-500 via-cyan-400 to-pink-500 text-transparent bg-clip-text bg-[length:200%_auto] animate-gradient"
              >
                {typeof prizePool === 'number' ? `${prizePool.toLocaleString()} HNL` : prizePool}
              </motion.p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
