'use client';

/* eslint-disable max-lines-per-function */

import Link from 'next/link';
// import Script from 'next/script';

import { usePathname } from 'next/navigation';

import Copyright from '@/features/footer/Copyright';
import FooterLinks from '@/features/footer/FooterLinks';
import Sponsors from '@/features/footer/Sponsors';

import Android from '@/public/img/footer/android.svg';

import Apple from '@/public/img/footer/apple.svg';

import { Separator } from '../ui/separator';

const Footer = () => {
  const pathname = usePathname();
  // useEffect(() => {
  //   if ((window as any).anj_1d4f86fd_7190_4f0e_9c0c_489e7aa1f045) {
  //     (window as any).anj_1d4f86fd_7190_4f0e_9c0c_489e7aa1f045.init();
  //   }
  // }, []);

  const excludedRoutes = ['/deposito'];

  // const { isSidebarOpen } = userUIStore();
  if (excludedRoutes.includes(pathname)) {
    return null;
  }

  return (
    <footer className="bg-gray flex flex-col h-full py-8 px-4 sm:px-8 border-t-2 border-sivarbet-secondary-bg lg:pl-28">

      <FooterLinks />
      <Separator className="bg-sivarbet-border my-4" />
      <Sponsors />
      <Separator className="bg-sivarbet-border my-4" />
      <Copyright />
      <div className="flex flex-wrap justify-center items-center gap-4 mb-10">
        <Link href="/landing/app" prefetch={false} className="p-1" style={{ width: '150px', marginTop: '10px' }}>
          <Android />
        </Link>
        <Link href="/landing/app" prefetch={false} className="p-1" style={{ width: '150px', marginTop: '10px' }}>
          <Apple />
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
