'use client';

import React, { useState, useEffect } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';

import { IoMenuSharp, IoCloseOutline } from 'react-icons/io5';

import { errors } from '@/localization';
import { userUIStore } from '@/stores/userUIStore';

import { useScrollTransparency } from '@/hooks/scrollhook';

import LoginModal from '@/components/modals/LoginModal';
import RegisterModal from '@/components/modals/RegisterModal';
import { Button } from '@/components/ui/button';

import LogoL from '@/public/img/logo/hondubetlogo.png';
import LogoS from '@/public/img/logo/hondubetsquare.png';

import { useToast } from '../ui/use-toast';

const PublicNavBar = () => {
  const isTransparent = useScrollTransparency();
  const { toggleSidebar, resetSidebar } = userUIStore();
  const [isActive, setActive] = useState(false);
  const [isCredentialsSigninError, setIsCredentialsSigninError] = useState<boolean>(false);

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const { toast } = useToast();

  const toggleActive = () => {
    setActive(!isActive);
  };

  const handleHeaderBarClick = () => {
    if (window.innerWidth > 768) {
      toggleActive();
      toggleSidebar();
    }
  };

  useEffect(() => {
    resetSidebar(); // Reset the sidebar state whenever the route changes
  }, [pathname, resetSidebar, searchParams]); // Reacting to changes in path or search params

  if (searchParams && searchParams.get('error') === 'CredentialsSignin' && !isCredentialsSigninError) {
    setIsCredentialsSigninError(true);
    toast({ title: errors.tryAgain, variant: 'destructive' });
  }

  return (
    <header className={`flex w-full fixed top-0 left-0 right-0 z-50 transition-all duration-500 ease-in-out ${
      isTransparent ? 'bg-transparent' : 'bg-sivarbet-background'
    }`}
    >
      <div className="container-fluid p-0 flex-1">
        <div className={`header-wrapper flex justify-between items-center p-2 px-4 max-lg:px-2 text-sivarbet-text border-none ${
          isTransparent ? 'bg-opacity-0' : 'bg-opacity-100'
        } transition-all duration-500 ease-in-out`}
        >
          <div
            className="pl-2 pr-9 flex justify-center items-center transition-all duration-1000 max-md:hidden"
          >
            <Button
              onClick={handleHeaderBarClick}
              variant="default"
              className="bg-transparent border-none p-0 hover:bg-none"
            >
              {!isActive ? (
                <IoMenuSharp className="text-4xl text-slategray transition-all duration-1000" />
              ) : (
                <IoCloseOutline className="text-4xl text-slategray transition-all duration-1000" />
              )}
            </Button>
            <Link href="/">
              <Image src={LogoL} alt="logo" width={198} height={90} className="ml-4" />
            </Link>
          </div>
          <div className="max-md:block hidden">
            <Link href="/">
              <Image src={LogoS} alt="logo" width={40} height={40} className="" />
            </Link>
          </div>
          <div className="flex gap-2">

            <LoginModal />
            <RegisterModal isDefaultOpen={isCredentialsSigninError} />

          </div>
        </div>
      </div>
    </header>
  );
};

export default PublicNavBar;
