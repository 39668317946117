'use client';

import React, { useCallback, useEffect, useState } from 'react';

import Image from 'next/image';

import { useRouter } from 'next/navigation';

import Autoplay from 'embla-carousel-autoplay';

import Fade from 'embla-carousel-fade';
import { isMobile } from 'react-device-detect';

import urlFor from '@/sanity/urlFor';

// import { userUIStore } from '@/stores/userUIStore';

// import { Button } from '@/components/ui/button';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel';

const BannerCarousel = ({ banners }: any) => {
  // const { toggleDepositSheet, toggleDepositModal, toggleLoginModal } = userUIStore();
  const [bannersSorted, setBannersSorted] = useState<any[]>([]);
  // const searchParams = useSearchParams();
  const router = useRouter();
  const handleBannerClick = useCallback((url: string) => {
    router.push(url);
  }, [router]);

  useEffect(() => {
    let bannersFiltered: any[] = [];
    if (isMobile) {
      bannersFiltered = banners.banners.filter((banner: any) => { return banner.isMobile; });
    } else {
      bannersFiltered = banners.banners.filter((banner: any) => { return !banner.isMobile; });
    }
    const sortedBanners = bannersFiltered.sort((a: any, b: any) => {
      if (a.orderRank && b.orderRank) {
        return a.orderRank.localeCompare(b.orderRank);
      }
      return 0; // No sorting if orderRank is missing
    });
    setBannersSorted(sortedBanners);
  }, [banners]);

  if (bannersSorted.length === 0) {
    return <div />;
  }
  // const action = searchParams.get('action');

  // useEffect(() => {
  //   async function fetchData() {
  //     const data = await fetch('/api/banners?type=home', {
  //       method: 'GET',
  //       cache: 'no-store',
  //       next: { revalidate: 0 },
  //     });

  //     if (!data.ok) {
  //       throw new Error('Failed to fetch banners');
  //     }

  //     const response = await data.json();

  //     const sortedBanners = response.banners.sort((a: { orderRank: string }, b: { orderRank: any }) => {
  //       return a.orderRank.localeCompare(b.orderRank);
  //     });
  //     setBanners(sortedBanners);
  //   }

  //   fetchData();
  // }, []);

  // const handleDepositModal = () => {
  //   if (window.innerWidth > 768) {
  //     toggleDepositModal();
  //   } else {
  //     toggleDepositSheet();
  //   }
  // };

  // useEffect(() => {
  //   const clearUrlParams = () => {
  //     const url = new URL(window.location.href);
  //     url.search = '';
  //     window.history.replaceState(null, '', url.toString());
  //   };

  //   if (action === 'login' && !isSession) {
  //     toggleLoginModal();
  //     clearUrlParams();
  //   }
  //   if (action === 'deposit' && isSession) {
  //     // handleDepositModal();
  //     clearUrlParams();
  //   }
  // }, [action, isSession, toggleLoginModal, toggleDepositModal, toggleDepositSheet]);

  return (
    <div className="relative w-full">
      <Carousel
        className="h-full w-full max-lg:w-[100vw] max-lg:-ml-4 rounded-lg"
        plugins={[Fade(), Autoplay({ delay: 8000 })]}
        opts={{
          align: 'start', // Align slides to the start
        }}
      >
        <CarouselContent className="w-full max-lg:m-0 max-lg:p-0">
          {bannersSorted.map((banner, index) => {
            const imageUrl = urlFor(banner.imageUrl).url();
            return (
              // eslint-disable-next-line no-underscore-dangle
              <CarouselItem key={banner._id} className="w-full  max-lg:m-0 max-lg:p-0">
                <div className="relative h-auto w-full rounded-lg overflow-hidden">
                  <div className="relative w-full h-full">
                    <Image
                      src={imageUrl}
                      alt="banner image"
                      width="1600"
                      height="400"
                      sizes="100vw"
                      className="h-auto w-full"
                      onClick={() => { return handleBannerClick(banner.urlRedirection); }}
                      priority={index === 0}
                    />
                  </div>
                </div>
                {/* {banner.title && (
                    <div className="absolute z-10 top-0 p-4 sm:p-6 md:p-8 flex flex-col justify-center items-start h-full text-center">
                      <h1 className="text-lg max-sm:text-lg md:text-2xl lg:text-4xl font-bold text-sivarbet-text">
                        {banner.title}
                      </h1>
                      <span className="text-sm max-sm:text-sm md:text-lg font-normal text-sivarbet-secondary-text mt-2">
                        Description goes here
                      </span>
                      {isSession ? (
                        <Button className="btn-banner" onClick={handleDepositModal}>
                          Deposita Ya!
                        </Button>
                      ) : (
                        <Button className="btn-banner" onClick={toggleRegisterModal}>
                          Regístrate
                        </Button>
                      )}
                    </div>
                  )} */}
              </CarouselItem>
            );
          })}
        </CarouselContent>
      </Carousel>
    </div>
  );
};

export default BannerCarousel;
