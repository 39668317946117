import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel';
import { Skeleton } from '@/components/ui/skeleton';

export default function LoadingCarousel() {
  // Create two rows of skeletons
  const rows = [
    { title: 'Popular', items: 6 },
    { title: 'Recomendados', items: 6 },
  ];

  return (
    <div className="flex flex-col gap-4">
      {rows.map((row) => {
        return (
          <div key={row.title} className="w-full">
            <Carousel className="mb-1 mt-2 w-full">
              <div className="flex items-center justify-between pb-3">
                {/* Section title skeleton */}
                <div className="flex items-center gap-2">
                  <Skeleton className="h-6 w-6 rounded-full" />
                  <Skeleton className="h-7 w-32" />
                </div>
                {/* Navigation buttons skeleton */}
                <div className="flex gap-3">
                  <Skeleton className="h-8 w-8 rounded-lg" />
                  <Skeleton className="h-8 w-8 rounded-lg" />
                </div>
              </div>

              <CarouselContent className="gap-1">
                {Array.from({ length: row.items }).map((_, index) => {
                  return (
                    <CarouselItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      className="basis-1/3 md:basis-1/3 lg:basis-[13.5%]"
                    >
                      <div className="flex flex-col gap-2">
                        {/* Game thumbnail skeleton */}
                        <Skeleton className="aspect-[1/1] w-full rounded-xl" />

                        {/* Game title and icons skeleton */}
                        <div className="flex items-center justify-between">
                          <Skeleton className="h-4 w-[70%]" />
                          <div className="flex gap-1">
                            <Skeleton className="h-4 w-4 rounded-full" />
                            <Skeleton className="h-4 w-4 rounded-full" />
                          </div>
                        </div>

                        {/* Provider name skeleton */}
                        <Skeleton className="h-3 w-[40%]" />
                      </div>
                    </CarouselItem>
                  );
                })}
              </CarouselContent>
            </Carousel>
          </div>
        );
      })}
    </div>
  );
}
