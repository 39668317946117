'use client';

import React, { useEffect } from 'react';

import Script from 'next/script';

declare global {
  interface Window { anj_1d4f86fd_7190_4f0e_9c0c_489e7aa1f045?: { init: () => void; }; }
}

const Copyright = () => {
  useEffect(() => {
    if ((window as any).anj_1d4f86fd_7190_4f0e_9c0c_489e7aa1f045) {
      (window as any).anj_1d4f86fd_7190_4f0e_9c0c_489e7aa1f045.init();
    }
  }, []);
  return (
    <>
      <Script
        src="https://d8eac85a-6ddc-4b18-bc18-8d7627783300.snippet.anjouangaming.org/anj-seal.js"
        strategy="lazyOnload"
      />
      <div className="flex flex-col gap-7 items-center justify-center my-4">
        <div id="anj-d8eac85a-6ddc-4b18-bc18-8d7627783300" data-anj-seal-id="d8eac85a-6ddc-4b18-bc18-8d7627783300" data-anj-image-size="80" data-anj-image-type="basic-small" />
        <span className="text-sivarbet-primary">Copyright © 2024 interplay.tech - Todos los derechos reservados</span>
        <p className="text-wrap text-center text-sivarbet-third-text w-full lg:w-1/2">
          hondubet.com is owned and operated by interplay tech ltd.
          registraon number:000038107, registered address: 2118 Guava Street, Belama Phase1, Belize city, Belize . Contact us info@interplay.tech. hondubet.com is licensed and regulated by the Government of the Autonomous
          Island of Anjouan, Union of Comoros and operates under License No.ALSI-032401020-FI3. hondubet.com has passed all
          regulatory compliance and is legally authorized to conduct gaming operations for any and all games of chance and wagering.Payment services are provided by Interplay Tech PA Limited,
          registered at Voukourestiou 25, Neptune House, 1st Floor, Flat/Office 11, 3045, Limassol, Cyprus.
        </p>
      </div>
    </>
  );
};

export default Copyright;
