'use client';

import React, { useState, useEffect } from 'react';

import Image from 'next/image';

import { useSession } from 'next-auth/react';
import { isMobile } from 'react-device-detect';
import { FaEnvelope } from 'react-icons/fa6';

import { MdKeyboardArrowDown } from 'react-icons/md';

import { useAuthStore } from '@/stores/userStore';

import { decrypt } from '@/utils/core';

import { LOCAL } from '@/constants/core';

import { Button } from '../ui/button';
import { ScrollArea } from '../ui/scroll-area';
import { Separator } from '../ui/separator';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../ui/sheet';
import { useToast } from '../ui/use-toast';

const mockMessages = [
  {
    id: '1',
    isOpened: false,
    create_time: 1718304000, // Represents a date in UNIX timestamp format
    message: {
      title: <strong>Welcome to Xtremepush!</strong>,
      alert: (
        <p>
          Discover how you can engage effectively with your users. Lorem ipsum
          dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem
          ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
          voluptatem ea dicta ex dolorum minus quibusdam molestiae eaque dolorem
          odio aliquam, exercitationem, placeat inventore assumenda nam quidem
          odit, eius enim.
          <br />
          <br />
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam,
          quos. Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          Laboriosam voluptatem ea dicta ex dolorum minus quibusdam molestiae
          eaque dolorem odio aliquam, exercitationem, placeat inventore
          assumenda nam quidem odit, eius enim.
          <br />
          <br />
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam,
          quos. Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          Laboriosam voluptatem ea dicta ex dolorum minus quibusdam molestiae
          eaque dolorem odio aliquam, exercitationem, placeat inventore
          assumenda nam quidem odit, eius enim.
          <br />
          <br />
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam,
          quos. Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          Laboriosam voluptatem ea dicta ex dolorum minus quibusdam molestiae
          eaque dolorem odio aliquam, exercitationem, placeat inventore
          assumenda nam quidem odit, eius enim.
        </p>
      ),
      icon: '/img/background/Bgweb-marketing.png',
    },
  },
  {
    id: '2',
    isOpened: false,
    create_time: 1718400000,
    message: {
      title: <strong>Get Started with our SDK</strong>,
      alert: (
        <p>Integrate our SDK to leverage the full power of our platform.</p>
      ),
      icon: '/img/background/laligabanner.png',
    },
  },
  {
    id: '3',
    isOpened: true,
    create_time: 1718500000,
    message: {
      title: <strong>Latest Features</strong>,
      alert: <p>Check out the latest features we&apos;ve released!</p>,
      icon: '/img/background/laligabanner.png',
    },
  },
  {
    id: '4',
    isOpened: false,
    create_time: 1718304000, // Represents a date in UNIX timestamp format
    message: {
      title: <strong>Welcome to Xtremepush!</strong>,
      alert: <p>Discover how you can engage effectively with your users.</p>,
    },
  },
  {
    id: '5',
    isOpened: false,
    create_time: 1718400000,
    message: {
      title: <strong>Get Started with our SDK</strong>,
      alert: (
        <p>Integrate our SDK to leverage the full power of our platform.</p>
      ),
    },
  },
  {
    id: '6',
    isOpened: true,
    create_time: 1718500000,
    message: {
      title: <strong>Latest Features</strong>,
      alert: <p>Check out the latest features we&apos;ve released!</p>,
    },
  },
];

const NotificationsSheet = () => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [expandedId, setExpandedId] = useState(null); // Estado para manejar qué mensaje está expandido
  const { data: session } = useSession();
  const { notifyAccepted, setNotifyAccepted } = useAuthStore();
  const { isxtremepushSet, setXtremepush } = useAuthStore();
  const { toast } = useToast();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV === LOCAL) {
      setNotifications(mockMessages);
    }
    const fetchMessages = async () => {
      const userId = await decrypt(
        session?.user?.data ?? '',
        session?.user?.tag ?? '',
      );
      if ((window as any).xtremepush) {
        if (!isxtremepushSet) {
          (window as any).xtremepush('set', 'user_id', userId);
          setXtremepush();
        }
        await (window as any).xtremepush(
          'inbox',
          'message.list',
          {
            limit: 10,
            offset: 0,
          },
          (result: any) => {
            if (result.items && result.items.length > 0) {
              setNotifications(
                result.items.map((item: any) => {
                  return { ...item, isOpened: false };
                }),
              );
            }
          },
        );
      }
    };
    if (session?.user?.data && session?.user?.tag) {
      fetchMessages();
    }
  }, [isxtremepushSet, session?.user?.data, session?.user?.tag, setXtremepush]);

  const handleNotify = () => {
    if ((window as any).xtremepush) {
      (window as any).xtremepush('ready', () => {
        // Checking current user permission

        const permission = (window as any).xtremepush('push', 'permission');

        if (permission === 'default') {
          (window as any).xtremepush('push', 'prompt', {
            allowCallback() {
              // User clicked allow. Removing subscribe button
              setNotifyAccepted();

              toast({
                title: 'Notificaciones Acceptadas',
                description: 'Ya has aceptado las notificaciones exitosamente!',
                className: 'bg-sivarbet-secondary',
              });
            },
            denyCallback() {
              // User clicked deny. Removing subscribe button
              setNotifyAccepted();
              toast({
                title: 'Notificaciones Rechazas',
                description: 'Ya has rechazado las notificaciones',
                variant: 'destructive',
              });
            },
            dismissCallback() {
              // User dismissed dialog. It is possible to prompt again later
            },
          });
        } else {
          switch (permission) {
            case 'granted':
              setNotifyAccepted();
              toast({
                title: 'Notificaciones Acceptadas',
                description: 'Ya has aceptado las notificaciones exitosamente!',
                className: 'bg-sivarbet-secondary',
              });
              break;
            case 'denied':
              setNotifyAccepted();
              toast({
                title: 'Notificaciones Rechazas',
                description: 'Ya has rechazado las notificaciones',
                variant: 'destructive',
              });
              break;
            case 'unavailable':
              // Push messaging is not available in user browser
              break;
            default:
              break;
          }
        }
      });
    }
  };

  const handleToggleMessage = (id: React.SetStateAction<null>) => {
    const message = notifications.find((notif) => {
      return notif.id === id;
    });
    const isExpanded = expandedId === id;
    setExpandedId(isExpanded ? null : id);

    if (!message.isOpened) {
      setNotifications(
        notifications.map((notification) => {
          return notification.id === id ? { ...notification, isOpened: true } : notification;
        }),
      );
    }
    if (!isExpanded && !message.isOpened) {
      // @ts-ignore
      xtremepush(
        'inbox',
        'message.action',
        {
          id,
          open: 1, // Marcar como abierto
        },
        () => {
          // Marcar el mensaje como abierto en el estado
          const updatedNotifications = notifications.map((notif) => {
            return notif.id === id ? { ...notif, isOpened: true } : notif;
          });
          setNotifications(updatedNotifications);
        },
        (error: any) => {
          // eslint-disable-next-line no-console
          console.error('Failed to mark message as opened:', error);
        },
      );
    }
  };

  const timeAgo = (time: number) => {
    const now = new Date();
    const createTime = new Date(time * 1000);
    const timeDiff = now.getTime() - createTime.getTime();
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  };

  return (
    <Sheet>
      <SheetTrigger className="mr-2">
        {notifications.some((notification) => {
          return notification.opened !== 1;
        }) ? (
          // eslint-disable-next-line react/jsx-indent
            <div className="flex flex-row-reverse">
              <div className="w-2 h-2 rounded-full bg-sivarbet-secondary rigth-3 z-10 absolute" />
              <FaEnvelope className="text-sivarbet-text text-2xl mr-[3px]" />
            </div>
          ) : (
            <FaEnvelope className="text-sivarbet-text text-2xl" />
          )}
      </SheetTrigger>
      <SheetContent
        side={isMobile ? 'bottom' : 'right'}
        className="bg-sivarbet-background border-sivarbet-border text-sivarbet-text h-full w-full rounded-t shadow-2xl shadow-gray backdrop-opacity-30 px-0 pt-0 pb-20"
      >
        <SheetHeader className="place-items-start bg-sivarbet-border py-2">
          <SheetTitle className="flex items-baseline justify-center px-3 w-full bg-sivarbet-border">
            <span className="text-lg text-sivarbet-text">Notificaciones</span>
          </SheetTitle>
        </SheetHeader>
        <ScrollArea className="h-full w-full">
          <div className="bg-sivarbet-background text-sivarbet-text border-sivarbet-border pb-0 mr-7 w-full">
            {notifications.length > 0 ? (
              <>
                {notifications.map((notification) => {
                  return (
                    <div
                      key={notification.id}
                      className="flex flex-col items-start m-3 focus:text-sivarbet-text bg-sivarbet-secondary-bg p-2 rounded-md "
                      onClick={() => {
                        handleToggleMessage(notification.id);
                      }}
                    >
                      <div className="flex items-baseline gap-2">
                        <span className="text-xs text-sivarbet-secondary-text">
                          Hace
                          {' '}
                          {timeAgo(notification.create_time)}
                          {' '}
                          dias
                        </span>
                        <div
                          className={`w-2 h-2 rounded-full bg-sivarbet-secondary ${notification.opened === 1 ? 'hidden' : ''}`}
                        />
                      </div>
                      <div className="flex flex-col justify-between gap-3 w-full">
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: notification.message.title || notification.title }} />

                        {/* {notification.message.title} */}
                        <Image
                          src={notification.message.icon}
                          alt="notification-image"
                          layout="responsive"
                          width={100}
                          height={100}
                          className="!w-full !h-full object-fill object-top rounded-md"
                        />
                        <div
                          className={`${expandedId === notification.id ? 'h-full' : 'h-24 overflow-hidden'}`}
                        >
                          {/* {notification.message.alert} */}
                          {/* eslint-disable-next-line react/no-danger */}
                          <div className="!text-xs" dangerouslySetInnerHTML={{ __html: notification.message.alert || notification.alert }} />
                        </div>
                        <div className="flex items-center gap-1 text-xs text-sivarbet-secondary-text cursor-pointer focus:underline">
                          <span
                            onClick={() => {
                              handleToggleMessage(notification.id);
                            }}
                          >
                            Ver más
                          </span>
                          <MdKeyboardArrowDown
                            className={`${expandedId === notification.id ? 'rotate-180' : ''} text-lg`}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div>
                <span>No notifications</span>
                <span className="icon icon-bell_notification text-9xl">
                  <span className="path1 text-sivarbet-text" />
                  <span className="path2 text-sivarbet-text" />
                </span>
              </div>
            )}
            {!notifyAccepted && (
              <>
                <Separator className="bg-sivarbet-border my-3" />
                <div className="w-full flex items-center justify-around">
                  <Button
                    id="btn-notify"
                    onClick={() => {
                      return handleNotify();
                    }}
                    className="bg-sivarbet-primary"
                  >
                    Suscribirse a las notificaciones
                  </Button>
                </div>
              </>
            )}
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

export default NotificationsSheet;
