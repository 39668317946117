'use client';

import { memo, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Eye, EyeOff } from 'lucide-react';
import { useForm } from 'react-hook-form';
// import Turnstile, { useTurnstile } from 'react-turnstile';

import Turnstile from 'react-turnstile';
import { z } from 'zod';

import { messages } from '@/features/auth/localization';

import PasswordStrengthMeter from '@/features/validatePassword/PasswordStrengthMeter';
import { userUIStore } from '@/stores/userUIStore';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl, FormField, FormItem, FormLabel, FormMessage,
} from '@/components/ui/form';

import { Input } from '../../../components/ui/input';
import { loginEmailAndPass, registerEmailAndPass } from '../schema';

interface Props {
  action: (values: z.infer<any>) => void,
  disabled: boolean,
  isRegister: boolean,
}

const FormEmailAndPassword = ({ action, disabled, isRegister }:Props) => {
  const { toggleCheckbox } = userUIStore();
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  // const turnstile = useTurnstile();
  const apiKey = process.env.NEXT_PUBLIC_TURNSTILE_API_KEY ?? '';

  const formSchema = isRegister ? registerEmailAndPass.extend({
    turnstileToken: z.string().min(1, 'Please complete the Cloudflare challenge'),
    ageVerification: z.boolean().default(false),
  }) : loginEmailAndPass.extend({
    turnstileToken: z.string().min(1, 'Please complete the Cloudflare challenge'),
    ageVerification: z.boolean().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(isRegister ? registerEmailAndPass : loginEmailAndPass),
    defaultValues: {
      email: '', password: '', ageVerification: false, turnstileToken: '',
    },
    mode: 'onChange',
  });
  const onSubmit = (values: z.infer<typeof formSchema>) => {
    action({ ...values, turnstileToken });
  };

  const handleValidation = async (token: string) => {
    try {
      const response = await fetch('/api/validate-turnstile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      if (!data.success) {
        // Token invalid, reset the widget
        // turnstile.reset();
        // eslint-disable-next-line no-console
        console.log(data.errors);
      }
    } catch (error) {
      // turnstile.reset();
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  return (
    <div className="flex flex-col zyy gap-2 w-full">
      <Form
        watch={form.watch}
        getValues={form.getValues}
        getFieldState={form.getFieldState}
        setError={form.setError}
        clearErrors={form.clearErrors}
        setValue={form.setValue}
        trigger={form.trigger}
        formState={form.formState}
        resetField={form.resetField}
        reset={form.reset}
        handleSubmit={form.handleSubmit}
        unregister={form.unregister}
        control={form.control}
        register={form.register}
        setFocus={form.setFocus}
      >
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 mx-[17px] mb-6">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={messages.email}
                      onChange={(value) => { field.onChange(value.target.value.toLowerCase()); }}
                      value={field.value}
                      name={field.name}
                      type="email"
                      onBlur={field.onBlur}
                      className="input-form-text"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        placeholder={messages.password}
                        onChange={field.onChange}
                        value={field.value}
                        name={field.name}
                        onBlur={field.onBlur}
                        className="input-form-text pr-10"
                      />
                      <button
                        type="button"
                        onClick={() => { return setShowPassword(!showPassword); }}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2"
                      >
                        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                      </button>
                    </div>
                  </FormControl>
                  {isRegister && <PasswordStrengthMeter password={field.value} />}
                </FormItem>
              );
            }}
          />
          {/*
            {isRegister && idAffiliate !== null ? (
              <>
                <h1 className="flex items-start justify-start relative text-inherit leading-[20px] font-medium font-inherit mx-[17px] mb-8">
                  {`${messages.affiliateCode}`}
                </h1>
                <FormField
                  control={form.control}
                  name="ID Afiliado"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormControl>
                          <div className="relative">
                            <Input
                              type="text"
                              placeholder={messages.idAffiliatemessage}
                              value={idAffiliate ?? ''}
                              name={field.name}
                              readOnly
                              className="input-form-text pr-10"
                            />
                          </div>
                        </FormControl>
                      </FormItem>
                    );
                  }}
                />
              </>
            ) : null} */}

          {isRegister && (
            <FormField
              control={form.control}
              name="ageVerification"
              render={({ field }) => {
                return (
                  <FormItem className="w-full flex flex-row items-center justify-center">
                    <FormControl className="mr-4">
                      <Checkbox
                        onCheckedChange={field.onChange}
                        className="!bg-sivarbet-primary scale-150"
                        checked={field.value}
                        name={field.name}
                        ref={field.ref}
                        onBlur={field.onBlur}
                        onClick={() => {
                          toggleCheckbox();
                          field.onChange(!field.value);
                        }}
                      />
                    </FormControl>

                    <FormLabel>{messages.messagesRegister}</FormLabel>
                  </FormItem>
                );
              }}
            />
          )}
          {isRegister && (
            <div className="flex flex-col gap-1 justify-center items-center">

              <FormField
                control={form.control}
                name="turnstileToken"
                render={() => {
                  return (
                    <FormItem>
                      <FormControl>
                        <Turnstile
                          sitekey={apiKey}
                          language="es"
                          onVerify={(token) => {
                            setTurnstileToken(token);
                            handleValidation(token);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>
          )}

          <div className="flex flex-col gap-1">
            <Button disabled={disabled || (!turnstileToken && isRegister)} type="submit" className=" marketing-button button-primary-full hover:bg-sivarbet-primary-hover">
              <div className="relative text-sm leading-[10px] font-medium font-text-sm-medium text-sivarbet-secondary-bg text-center inline-block min-w-[35px]">
                {isRegister ? messages.register : messages.login}
              </div>
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default memo(FormEmailAndPassword);
