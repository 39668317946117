/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */

'use client';

import React, { HTMLAttributes } from 'react';

import Link from 'next/link';

import { cn } from '@/lib/utils';

import { NavProps } from '@/utils/types/navbartypes';

import { buttonVariants } from '@/components/ui/button';
import { SheetClose } from '@/components/ui/sheet';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

interface LinkItemProps extends HTMLAttributes<HTMLAnchorElement> {
  link: NavProps['links'][number];
  isCollapsed: boolean;
  withSheetClose?: boolean;
}

const LinkItem = ({ link, isCollapsed, withSheetClose }: LinkItemProps) => {
  const [SheetCloseWrapper, sheetCloseWrapperProps] = withSheetClose ? [SheetClose, { asChild: true }] : [React.Fragment, {}];

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <SheetCloseWrapper {...sheetCloseWrapperProps}>
          <Link
            prefetch={false}
            data-testid="link"
            href={link.href ?? '/'}
            className={cn(
              buttonVariants({
                size: isCollapsed ? 'icon' : 'sm',
              }),
              'p-0 text-base bg-sivarbet-background hover:bg-sivarbet-primary dark:bg-muted dark:text-sivarbet-text dark:hover:bg-muted dark:hover:bg-sivarbet-primary data-[state=active]:bg-sivarbet-primary rounded-xl',
              isCollapsed ? 'h-9 w-full hover:bg-sivarbet-primary flex items-center justify-center' : 'justify-start hover:bg-sivarbet-primary',
            )}
          >
            {isCollapsed ? (
              <TooltipTrigger asChild>
                <span className="p-4 flex items-baseline gap-3">
                  {typeof link.icon === 'string' ? (
                    <span className={`h-4 w-4 'mr-2' icon icon-${link.icon} 'text-base'`} />
                  ) : (
                    <>{link.icon}</>
                  )}
                </span>
              </TooltipTrigger>
            ) : (
              <span className="p-4 flex items-baseline gap-3">
                {typeof link.icon === 'string' ? (
                  <span className={`h-4 w-4  icon icon-${link.icon} 'text-base'`} />
                ) : (
                  <>{link.icon}</>
                )}
                <span className="text-base">
                  {link.title}
                </span>
              </span>
            )}

            {!isCollapsed && link.label && (
              <span className={cn('ml-auto', 'text-background dark:text-sivarbet-text')}>
                {link.label}
              </span>
            )}
          </Link>
        </SheetCloseWrapper>
        {isCollapsed && (
          <TooltipContent side="right" className="flex items-center gap-4 bg-sivarbet-border text-sivarbet-secondaryext border-none rounded-xl">
            {link.title}
            {link.label && (
              <Link
                prefetch={false}
                data-testid="link"
                href={link.href ?? '/'}
              >
                <span className="ml-auto text-muted-foreground">{link.label}</span>
              </Link>
            )}
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};

export default LinkItem;
