import freeGame from './100Mov.png';
import _100o100Movil from './100o100Mov.png';
import _100o100 from './100o100Web.png';
import promotionImage from './ball-1.png';
import promotionImage2 from './ball-2.png';
import IconBall from './Ball.png';
import bancos from './bancos.png';
import bannerBonus from './bannerBonus.png';
import bono from './bono.webp';
import bonoCasinoMovil from './bonoCasinoMovil.jpg';
import bonoCasino from './bonoCasinoWeb.jpg';
import bonos from './bonos.png';
import bonoSport from './bonoSportWeb.jpg';
import bonoSportMovil from './bonosSportMovil.jpg';
import girlMovil from './chicaMov.png';
import boyMovil from './chicoMov.png';
import coinsBonus from './coinsBonus.png';
import defaultBannerMovil from './defaultMovil.jpg';
import defaultBanner from './defaultWeb.jpg';
import IconDice from './Dice.png';
import footer from './footer.png';
import freeSpinsMov from './freeSpingMov.png';
import loginBannerMovil from './loginMovil.webp';
import loginBanner from './loginWeb.webp';
import master from './master.png';
import moneybang from './moneybang.png';
import claimWeb from './reclamaWeb.png';
import slots from './slots.png';
import sorteos from './sorteos.png';
import sportBonus from './sportBonus.png';
import star from './star.png';
import step1 from './step1.png';
import step2 from './step2.png';
import step3 from './step3.png';
import tigo from './tigo.png';
import torneos from './torneos.png';
import trans from './trans.png';
import IconTrash from './trash.png';
import visa from './visa.png';
import winBono100 from './Win.webp';

export {
  IconBall, IconDice, IconTrash, promotionImage,
  promotionImage2, moneybang, slots, star, sorteos,
  torneos, bonos, coinsBonus, sportBonus, bannerBonus,
  bonoCasino, bonoSport, bonoCasinoMovil, bonoSportMovil,
  loginBanner, loginBannerMovil, defaultBanner, defaultBannerMovil,
  freeGame, _100o100Movil, girlMovil, boyMovil, freeSpinsMov,
  bancos, footer, claimWeb, _100o100, winBono100, step1, step2, step3,
  master, visa, tigo, trans, bono,
};
