// import { useAuthStore } from '@/stores/userStore';
import { useEffect, useState } from 'react';

import { usePathname, useRouter } from 'next/navigation';

import { ContentDeposit, BankDepositForm } from '@/features/deposit/components';
import { useAuthStore } from '@/stores/userStore';
import { userUIStore } from '@/stores/userUIStore';

import { Button } from '@/components/ui/button';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

const DepositModal = () => {
  const [modalToShow, setModalToShow] = useState<String>('ContentDeposit');
  const [showBankOptions, setShowBankOptions] = useState<Boolean>(false);
  const { isDepositModalOpen, toggleDepositModal } = userUIStore();
  const { account } = useAuthStore();
  const router = useRouter();
  const pathname = usePathname();
  const excludePaths = ['/deposito'];

  useEffect(() => {}, [modalToShow]);

  const renderModal = ():any => {
    switch (modalToShow) {
      case 'BankDepositForm':
        return <BankDepositForm setModalToShow={() => { setModalToShow('ContentDeposit'); }} setShowBankOptions={(value) => { setShowBankOptions(value); }} />;
      default:
        return <ContentDeposit handleCashDepositForm={() => { setModalToShow('BankDepositForm'); }} showBankOptions={showBankOptions} />;
    }
  };

  return (
    <Dialog
      open={isDepositModalOpen}
      modal
      onOpenChange={toggleDepositModal}
    >

      {!excludePaths.includes(pathname) && (
        <DialogTrigger className="py-0">
          <Button
            asChild
            className="bg-[radial-gradient(circle,_rgb(149,251,63)_60%,_rgb(70,252,109)_100%)] text-sivarbet-border rounded-lg max-md:w-[100px] py-0 max-md:h-[35px]  hover:bg-[radial-gradient(circle,_rgb(149,251,63)_60%,_rgb(70,252,109)_100%)]/50"
            onClick={() => {
              setModalToShow('ContentDeposit');
              if (account?.contingencies?.deposits) {
                router.push('/?cont=4');
              }
            }}
          >
            <div>
              <span className="icon icon-wallet text-2xl max-md:!text-xl" />
              <span className="px-2 max-md:!text-base">Deposito</span>
            </div>
          </Button>
        </DialogTrigger>
      )}
      <DialogContent className="max-w-lg mx-auto] border-sivarbet-border bg-sivarbet-background text-sivarbet-text rounded-lg shadow-2xl shadow-black ">
        <DialogHeader>
          <DialogTitle className="flex items-baseline gap-2 text-start font-medium text-base">
            <span className="icon icon-wallet" />
            Deposito
          </DialogTitle>
        </DialogHeader>
        {renderModal()}
      </DialogContent>
    </Dialog>
  );
};

export default DepositModal;
