import React from 'react';

import Image from 'next/image';

import { Separator } from '@/components/ui/separator';

import bac from '@/public/img/footer/bac.png';
import banpais from '@/public/img/footer/banpais.png';
import bitcoin from '@/public/img/footer/bitcoin.webp';
import {
  genesis, JutiCalpa, ligaH, lobos, olancho, olimpia, RealSociedad, victoria, h, marathon, Motagua, RealDE,
} from '@/public/img/footer/Sponsors';

import tigo from '@/public/img/footer/tigo.png';
import visamaster from '@/public/img/footer/visamaster.png';
// import wallet from '@/public/img/footer/wallet.png';

const Sponsors = () => {
  return (
    <>
      <div className="flex flex-row  flex-wrap justify-evenly items-center my-4  gap-8 ">
        <Image src={bac} alt="Bac Credomatic" width={100} height={34.5} className="lg:scale-150 object-fill max-lg:max-w-[80px]" />
        <Image src={banpais} alt="Banpais" width={100} height={37} className="lg:scale-150 object-fill max-lg:max-w-[80px]" />
        <Image src={tigo} alt="Tigo" width={100} height={30} className="lg:scale-150 object-fill max-lg:max-w-[80px]" />
        <Image src={bitcoin} alt="Criptos" width={125} height={40} className="lg:scale-105 object-contain max-lg:max-w-[70px]" />
        <Image src={visamaster} alt="Visa Master" width={110} height={60} className="lg:scale-150 object-fill max-lg:max-w-[80px]" />
      </div>
      <Separator className="bg-sivarbet-border my-4" />
      <h4 className="font-bold">Patrocinadores oficiales:</h4>
      <div className="flex flex-col items-center my-8 gap-5">
        <div className="flex items-center justify-evenly gap-10">
          <Image src={ligaH} alt="LigaHondubet" className="lg:!w-[120px] object-fill  !w-[90px]" />
          <Image src={h} alt="Honduras" className="lg:!w-[300px] object-fill  !w-[180px]" />
        </div>
        <div className="flex flex-col md:flex-row items-center justify-evenly gap-10 md:gap-0">
          <div className="flex items-center justify-evenly gap-5">
            <Image src={Motagua} alt="Motagua" className="lg:!w-[100px] object-fill !w-[70px]" />
            <Image src={RealDE} alt="RealDE" className="lg:!w-[100px] object-contain !w-[70px]" />
            <Image src={RealSociedad} alt="RealSociedad" className="lg:!w-[100px] object-contain !w-[70px]" />
            <Image src={marathon} alt="marathon" className="lg:!w-[100px] object-fill !w-[70px]" />
          </div>
          <div className="flex items-center justify-evenly gap-5 md:gap-10">
            <Image src={genesis} alt="Genesis" className="md:!w-[110px] object-fill !w-[40px]" />
            <Image src={olimpia} alt="Olimpia" className="md:!w-[90px] object-fill !w-[40px]" />
            <Image src={olancho} alt="Olancho" className="md:!w-[90px] object-fill !w-[40px]" />
            <Image src={victoria} alt="Victoria" className="md:!w-[90px] object-fill !w-[40px]" />
            <Image src={lobos} alt="Lobos" className="md:!w-[90px] object-fill !w-[40px]" />
            <Image src={JutiCalpa} alt="JutiCalpa" className="md:!w-[90px] object-fill !w-[40px]" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sponsors;
