import genesis from './GENESIS.png';
import h from './h.png';
import JutiCalpa from './JUTICALPA.png';
import ligaH from './ligahondubet.png';
import lobos from './LOBOS-UPNFM.png';
import marathon from './marathon.png';
import Motagua from './Motagua.webp';
import olancho from './OLANCHO.png';
import olimpia from './OLIMPIA.png';
import RealSociedad from './REAL-SOCIEDAD.png';
import RealDE from './RealDE.png';
import victoria from './VICTORIA.png';

export {
  h, Motagua,
  RealDE, ligaH, RealSociedad,
  lobos, marathon, olancho,
  olimpia, victoria, genesis,
  JutiCalpa,
};
