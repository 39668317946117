// import { transactions, users } from '@prisma/client';

import {
  // Currency, TransactionTypes, TransactionStates,
  ReferralStatus,
  TransactionStates,
  TransactionTypes,
  UserKYCLevel,
} from '@/enums/core';

import {
  exception,
} from './core';

import { prisma } from './prisma';
import { referralsRules } from './rules';

interface ReferralData {
  id: number;
  kycLevel: number;
  amount: number;
  userFullProfile: boolean;
  createdAt: Date;
  status: number;
}

interface RequestReferral {
  status: string;
  message: string;
}
interface UsersReferrals {
  id: number;
  created_at: Date;
  amount: number;
  status: string;
  email: string;
  validated: boolean;
  deposit: boolean;
  redemmed_day: number;
}
interface Referrals {
  referrals: UsersReferrals[];
  movements: {
    referreds_count: number;
    awarded_amount: number;
    pending_amount: number;
    approval_amount: number;
  };
}

class Referral {
  private userId: number;

  private listReferrals: any;

  private enumsReferrals = {
    noReferred: 'This user has no referrals',
    approval: 'The commission has been sent for approval',
    alreadySentToApproval: 'The commission has already sent to approval',
    rejected: 'The commission has been rejected',
    error: 'An error occurred while processing the commission',
  };

  constructor(userId: number) {
    this.userId = userId;
  }

  // ====> search referrals count by topup_id or full list referrals
  async searchReferrals(topupId: string | undefined = undefined) {
    if (topupId) {
      const result: any = await prisma.$queryRaw`
        SELECT COUNT(referrals.id) AS count
        FROM referrals
        INNER JOIN users ON users.id = referrals.user_id
        WHERE users.topup_id = ${topupId}
      `;
      this.listReferrals = result[0]?.count ? parseInt(result[0]?.count, 10) : 0;
      return this.listReferrals;
    }
    const referrals = await prisma.referrals.findMany({
      where: { user_id: this.userId },
      select: {
        id: true,
        created_at: true,
        amount: true,
        status: true,
        referred: {
          select: {
            id: true,
            email: true,
            kyc_level: true,
            transactions: {
              where: {
                type: TransactionTypes.DEPOSIT,
                status: TransactionStates.COMPLETE,
                amount: { gte: referralsRules.minDeposit },
              },
              select: {
                id: true,
              },
            },
          },
        },
      },
    });
    this.listReferrals = referrals;
    return this.listReferrals;
  }

  // ====> check if the user has a deposit >= minDeposit
  static async hasDeposit(userId: number) {
    const deposits = await prisma.transactions.findFirst({
      where: {
        user_id: userId,
        type: TransactionTypes.DEPOSIT,
        status: TransactionStates.COMPLETE,
        amount: { gte: referralsRules.minDeposit },
      },
    });
    return !!deposits;
  }

  // ====> validate if the referral is available to be sent for approval
  async validateReferrals(dataToValidate: ReferralData): Promise<boolean> {
    const {
      kyc, minDeposit, fullProfile, redeemDays,
    } = referralsRules;
    const id = this.userId;
    const today = new Date();
    const createdAt = new Date(dataToValidate.createdAt);
    createdAt.setDate(createdAt.getDate() + redeemDays);
    const hasDeposit = await Referral.hasDeposit(id);
    const isKycValid = dataToValidate.kycLevel === kyc;
    const isAmountValid = (dataToValidate.amount >= minDeposit || hasDeposit);
    const isProfileComplete = dataToValidate.userFullProfile === fullProfile;
    const isRedeemable = createdAt > today;
    // eslint-disable-next-line no-console
    console.log('🚀 ~ Referral ~ isRedeemable:', isRedeemable);
    if (!isRedeemable && dataToValidate.status === ReferralStatus.PENDING) {
      await Referral.updateCommissionByExpired([dataToValidate.id]);
    }

    return isKycValid && isAmountValid && isProfileComplete && isRedeemable;
  }

  // ====> verify and set commission for approval
  async verifyAndSetCommissionForApproval(amount: number): Promise<RequestReferral> {
    try {
      const conmmision = await prisma.referrals.findUnique({
        where: {
          referred_id: this.userId,
        },
        select: {
          id: true,
          created_at: true,
          status: true,
          users: {
            select: {
              kyc_level: true,
              profiles: {
                select: {
                  full_profile: true,
                },
              },
            },
          },
        },
      });
      if (!conmmision) {
        return { status: 'error', message: this.enumsReferrals.noReferred };
      }
      const dataToValidate = {
        id: conmmision.id,
        amount,
        kycLevel: conmmision.users.kyc_level || 0,
        userFullProfile: conmmision.users.profiles?.full_profile || false,
        createdAt: conmmision.created_at,
        status: conmmision.status || 0,
      };

      let message = '';
      let isValid = false;
      switch (conmmision?.status) {
        case ReferralStatus.PENDING:
          isValid = await this.validateReferrals(dataToValidate);
          if (isValid) {
            try {
              const result = await prisma.referrals.update({
                where: { id: conmmision.id },
                data: { status: ReferralStatus.APPROVAL },
              });
              // eslint-disable-next-line no-console
              console.log('🚀 ~ Referral ~ result:', result);
              message = this.enumsReferrals.approval;
              return { status: 'success', message };
            } catch (error) {
              await exception(error, { route: '/utils/referrals.ts: verifyAndSetCommissionForApproval', method: 'DB', req: { conmmision } });
            }
          }
          break;
        case ReferralStatus.APPROVAL:
          message = this.enumsReferrals.alreadySentToApproval;
          break;
        case ReferralStatus.REJECTED:
          message = this.enumsReferrals.rejected;
          break;
        default:
          break;
      }
      return { status: 'error', message };
    } catch (error) {
      await exception(error, { route: '/utils/referrals.ts: verifyAndSetCommissionForApproval', method: 'DB', req: { amount } });
      return { status: 'error', message: this.enumsReferrals.error };
    }
  }

  // ====> commission by api
  async commissionByApi(amount: number): Promise<RequestReferral> {
    const url = `${process.env.NEXT_PUBLIC_APP_ENV === 'prod' ? process.env.NEXT_PUBLIC_PRIMARY_DOMAIN : process.env.NEXT_PUBLIC_SECONDARY_DOMAIN}/api/referrals?type=commission&amount=${amount}&userId=${this.userId}`;
    const response = await fetch(url);
    const data = await response.json();
    return data;
  }

  // ====> update commission by expired
  static async updateCommissionByExpired(toExpire: number[]) {
    const listToExpire = await prisma.referrals.updateMany({
      where: {
        id: { in: toExpire },
      },
      data: { status: ReferralStatus.EXPIRED, amount: 0 },
    });
    return listToExpire;
  }

  // ====> get referrals list and format the data
  async getReferralsList(): Promise<Referrals> {
    await this.searchReferrals();

    const expiry = (createdAtParam: Date) => {
      const today = new Date();
      const createdAt = new Date(createdAtParam);
      createdAt.setDate(createdAt.getDate() + referralsRules.redeemDays);
      const diffTime = createdAt.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    };

    if (!this.listReferrals) {
      return {
        referrals: [],
        movements: {
          referreds_count: 0, awarded_amount: 0, pending_amount: 0, approval_amount: 0,
        },
      };
    }
    // eslint-disable-next-line no-console
    console.log('🚀 ~ Referral ~ this.listReferrals:', this.listReferrals);
    const response = this.listReferrals.length === 0 ? {
      referrals: [],
      movements: {
        referreds_count: 0, awarded_amount: 0, pending_amount: 0, approval_amount: 0,
      },
    } : {
      referrals: this.listReferrals.map((referral: any) => {
        return {
          created_at: referral.created_at,
          amount: referral.amount,
          status: referral.status,
          email: referral.referred.email,
          validated: referral.referred.kyc_level === UserKYCLevel.APPROVED,
          deposit: referral.referred.transactions.length > 0,
          redemmed_day: expiry(referral.created_at),
        };
      }),
      movements: this.listReferrals.reduce(
        (acc: any, referral: any) => {
          acc.referreds_count += 1;

          if (referral.status === ReferralStatus.APPROVAL) {
            acc.awarded_amount += Number(referral.amount);
            acc.approval_amount += Number(referral.amount);
          } else if (referral.status === ReferralStatus.PENDING) {
            acc.pending_amount += Number(referral.amount);
          }

          return acc;
        },
        {
          referreds_count: 0, awarded_amount: 0, pending_amount: 0, approval_amount: 0,
        },
      ),
    };
    // eslint-disable-next-line no-console
    console.log('🚀 ~ Referral ~ response:', response);
    const toExpire = response.referrals.filter((item: any) => { return item.redemmed_day <= 0 && item.status === ReferralStatus.PENDING; }).map((item: any) => { return item.id; });
    if (toExpire.length > 0) {
      await Referral.updateCommissionByExpired(toExpire);
    }
    return response;
  }

  // ====> add new referred
  async addNewReferred(topouid:string): Promise<any> {
    if (!topouid) return;
    try {
      await this.searchReferrals(topouid);
      const amout = referralsRules.commissions.find(
        (rule) => {
          const count = Number(this.listReferrals) || 0;
          return count > rule.minReferral && count <= rule.maxReferral;
        },
      ) || { comission: 280 };
      // eslint-disable-next-line no-console
      console.log('🚀 ~ Referral ~ amout:', amout);

      const result = await prisma.$queryRaw`
      INSERT INTO referrals (user_id, referred_id, amount, status)
      SELECT id, ${this.userId}, ${amout.comission}, ${ReferralStatus.PENDING}
      FROM users
      WHERE topup_id = ${topouid}
      RETURNING *;
    `;
      // eslint-disable-next-line no-console
      console.log('🚀 ~ Referral ~ result:', result);
    } catch (error) {
      await exception(error, { route: '/utils/referrals.ts: addNewReferred', method: 'DB', req: { topouid } });
    }
  }
}

export default Referral;
